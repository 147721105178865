import {
  HeroHomeSection,
  HowItWorksHomeSection,
  LaptopHomeSection,
  WorkersHomeSection,
  WhatWeCanOfferTwoHomeSection,
  WhatProblemHomeSection,
} from '@/components';
import { Box, useMediaQuery } from '@mui/material';
import type { NextPage } from 'next';
import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';


export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
}

const Home: NextPage = () => {
  const smallScreen = useMediaQuery(t => t.breakpoints.down('lg'));

  return (
    <Box>
      <HeroHomeSection />
      <Box py={9} />
      <HowItWorksHomeSection />
      <Box py={6} />
      <LaptopHomeSection />
      <Box py={8} sx={{ bgcolor: 'background.primary.main' }} />
      <WhatProblemHomeSection />
      <Box py={smallScreen ? 10 : 15} />
      <WhatWeCanOfferTwoHomeSection />
      <Box py={smallScreen ? 0 : 8} />
      <WorkersHomeSection />
    </Box>
  );
};

export default Home;
